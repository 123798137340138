/* Main Section */
.TaxSavingsCarousel-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px 20px 0px 20px;
  justify-content: center;
  background-color: #242424;
  box-sizing: border-box;
  scroll-behavior: smooth; /* Enable smooth scrolling */
  min-height: 70vh; /* Ensure consistent height for the section */
}

/* Title and Separator */
.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%; /* Prevent unexpected width changes */
}

.testimonials-separator {
  width: 80%;
  margin: 40px auto;
  border-bottom: 1.5px solid #800000;
}

.TaxSavingsCarousel-header {
  text-align: center;
  font-size: 2rem;
  letter-spacing: 8px;
  text-transform: uppercase;
  color: #fff;
  margin: 20px 0;
}

/* Card Styling */
.TaxSavingsCarousel-card {
  flex: 0 0 32%; /* Fixed percentage width to avoid dynamic resizing */
  max-width: 32%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  min-height: 400px; /* Ensures cards have a consistent height */
}

.TaxSavingsCarousel-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}

.TaxSavingsCarousel-image {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 5px;
  object-fit: cover;
  aspect-ratio: 16/9;
}

.TaxSavingsCarousel-title {
  font-size: 1.75em;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
  text-decoration: none;
  transition: text-decoration 0.3s ease;
}

.TaxSavingsCarousel-description {
  font-size: 1.1em;
  color: #555;
  line-height: 1.6;
  margin-top: 0;
  flex-grow: 1;
  max-height: 10vh;
}

/* Hover Effects */
.TaxSavingsCarousel-card:hover .TaxSavingsCarousel-title {
  text-decoration: underline solid #800000 4px;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .TaxSavingsCarousel-card {
    flex: 0 0 48%; /* Fixed width for 2 cards per row */
    max-width: 48%;
  }

}

@media (max-width: 768px) {
  .TaxSavingsCarousel-card {
    flex: 0 0 100%; /* Fixed width for 1 card per row */
    max-width: 100%;
  }

  .TaxSavingsCarousel-header {
    font-size: 1.6rem;
  }
}
