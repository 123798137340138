.profile-outer-container {
    width: 100vw;
    min-height: 100vh;
    background-color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    box-sizing: border-box;
    padding-left: 5%;
    padding-right: 5%;
    animation: scroll-in 1s forwards; /* Animation for logo scroll in */

  }
  
  .profile-container {
    display: flex;
    align-items: center;
    background-color: #242424;
    color: #ffffff;
    padding: 20px;
    max-width: 1000px;
    margin-top: 2%;
    margin-bottom: 5%;
    border-bottom: 2px solid #800000;
    border-top: 2px solid #800000;
  }
  
  .profile-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profile-image {
    clip-path: inset(2% 2% 5% 2%);
    width: 250px;
    height: auto;
    border-radius: 15px;
    object-fit: cover;
  }

  .profile-content {
    flex: 2;
    margin-left: 20px;
  }
  
  .profile-role {
    color: #800000;
    font-size: 1rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  
  .profile-name {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .profile-bio {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .profile-education {
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    opacity: 0.9;
    margin-bottom: 10px;
  }

  .profile-email {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .email-link {
    color: #ffffff;
    text-decoration: none;
    border-bottom: 1px dotted #ffffff;
  }

  .email-link:hover {
    color: #800000;
  }

  .linkedin-icon {
    display: inline-block;
  }
  
  @media (max-width: 1024px) {
    .profile-outer-container {
      padding-left: 8%;
      padding-right: 8%;
    }
    .profile-name {
      justify-content: center;
    }
  }

  @media (max-width: 768px) {
    .profile-container {
      flex-direction: column;
      padding: 20px;
    }
    .profile-content {
      margin-left: 0;
      margin-top: 20px;
      text-align: center;
    }
    .profile-name {
      justify-content: center;
    }
  }
  
  @media (max-width: 480px) {
    .profile-container {
      padding: 15px;
    }
    .profile-image {
      width: 200px;
    }
    .profile-name {
      font-size: 1.5rem;
      justify-content: center;
    }
    .profile-role {
      font-size: 0.9rem;
    }
    .profile-bio, .profile-education, .profile-email {
      font-size: 0.9rem;
    }
  }