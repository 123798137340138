/* Full-page black background */
body {
    background-color: #242424;
    font-family: Arial, sans-serif;
}

.servicedetails-outer-container {
    width: 100vw;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2%;
    box-sizing: border-box;
    animation: scroll-in 2s forwards; /* Animation for logo scroll in */
}

.servicedetails-container {
    width: 100%;
    max-width: 80vw; /* Adjusted for larger layouts */
    background-color: #242424;
    color: #ffffff;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 2px solid #800000;
    border-bottom: 2px solid #800000;
    box-sizing: border-box;
    text-align: center; /* Center aligns content for a balanced layout */
}

.servicedetails-image-container {
    margin-bottom: 20px; /* Adds spacing below the image */
}

.servicedetails-image {
    width: 80%;
    max-width: 40vw; /* Larger image on larger screens */
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    display: block;
    margin: 0 auto; /* Centers the image */
}

.servicedetails-content {
    margin-top: 20px;
}

.servicedetails-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    align-items: center;
    gap: 10px;
}

.servicedetails-description {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #ffffff;
    text-align: left; /* Ensures text is readable */
    padding-left: 20px;
    padding-right: 20px;
}

@media (max-width: 768px) {
    .servicedetails-container {
        padding: 20px 10px;
    }

    .servicedetails-title {
        font-size: 2rem;
    }

    .servicedetails-description {
        font-size: 1rem;
        line-height: 1.6;
    }
}

@media (max-width: 480px) {
    .servicedetails-image {
        max-width: 100%; /* Ensures image scales on smaller screens */
    }

    .servicedetails-title {
        font-size: 1.8rem;
    }

    .servicedetails-description {
        font-size: 0.9rem;
        line-height: 1.4;
    }
}
