.services-container {
  width: 100vw;
  margin: 0;
  padding-top: 75px;
  padding-bottom: 10px;
  background-color: #242424;
}

.services-title {
  text-align: center;
  color: #f5f5f5;
  margin-bottom: 30px;
  font-size: 2rem;
  letter-spacing: 8px;
  text-transform: uppercase;
}

.service-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  border-bottom: 1.5px solid #800000;
  padding: 10px;
  max-width: 1000px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.service-title {
  width: 15%;
  font-size: 1.5rem;
  font-weight: bold;
  color: #f5f5f5;
  text-transform: uppercase;
  letter-spacing: 8px;
  margin-bottom: 10px;
}

.service-description {
  width: 72%;
  padding: 1px;
  color: #d3d3d3;
  font-size: 1.2rem;
  line-height: 1.4;
  margin-bottom: 10px;
}

.service-bullets {
  margin-top: 10px;
  padding-left: 20px;
  color: #d3d3d3;
  font-size: 1.1rem;
  line-height: 1.5;
}

.service-bullets li {
  margin-bottom: 8px;
}

.view-all {
  text-align: center;
  margin-top: 40px;
  font-weight: bold;
  color: #f5f5f5;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .service-item {
    flex-direction: column;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
  .service-title,
  .service-description {
    width: 100%;
    text-align: left;
  }
}

@media (max-width: 480px) {
  .services-title {
    font-size: 1.6rem;
  }
  .service-item {
    flex-direction: column;
    margin-bottom: 20px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
  .service-title {
    width: 100%;
    text-align: left;
    font-size: 1.2rem;

  }
  .service-description {
    width: 100%;
    text-align: left;
    font-size: 1rem;
  }
  .view-all {
    font-size: 1rem;
    margin-top: 20px;
  }
}
