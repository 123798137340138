.logo-container {
  height: 60vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-container-img {
  width: 60%;
  height: auto;
  margin: 6px;
  padding: 50px 2px 2px;
  animation: scroll-in 1s forwards; /* Animation for logo scroll in */
}

/* Scroll-in animation for the logo */
@keyframes scroll-in {
  from {
    transform: translateY(-50px);
    opacity: 0; /* Start invisible */
  }
  to {
    transform: translateY(0);
    opacity: 1; /* Fade in */
  }
}

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;

}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

.logo-description {
  font-size: 28px; /* Increased font size for better readability */
  font-weight: 600; /* Semi-bold font weight */
  color: #fff; /* White text color for the main text */
  padding: 25px 35px; /* Generous padding for a spacious feel */
  text-align: center; /* Center align the text */
  -webkit-text-stroke: 0.5px #800000;
  border: 4px solid #800000; /* Maroon border */
  border-radius: 8px; /* Slightly rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); /* Stronger shadow for depth */
  margin: 20px auto; /* Center the div on the page */
  max-width: 600px; /* Set a max width */
  overflow: hidden; /* Prevent overflow */
  white-space: nowrap; /* Prevent line break */
  animation: typing 4s steps(40) forwards; /* Typing animation */
  position: relative; /* To position text shadow */
  font-family: 'Roboto', sans-serif;
}

/* Optional: Adding text-shadow for a more pronounced effect */
.logo-description::before {
  content: attr(data-text); /* Use the same text as the content */
  position: absolute;
  left: 0; /* Align with original text */
  top: 0; /* Align with original text */

  color: #800000; /* Maroon text for trim effect */
  z-index: 4; /* Position behind the main text */
  filter: blur(2px); /* Slight blur for shadow effect */
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .logo-description {
    font-size: 20px; /* Slightly smaller font size for tablets */
    padding: 15px 20px; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .logo-description {
    font-size: 18px; /* Smaller font size for mobile */
    padding: 10px 15px; /* Less padding for mobile screens */
  }
}

