.cards__item__email {
    margin: 5px;
}

.cards__item__phone {
    margin: 5px;

}

.cards__item__phone_text {
    font-size: 12px;
    color:#500000;
    font-weight: 500;
}

.cards__item__email_text {
    font-size: 12px;
    color:#500000;
    font-weight: 500;
}

.overlay {
    position: absolute;
    top: 78%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #500000;
    color: #fff;
    padding: 10px 20px;
    border: 0px;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;
    text-decoration: none;
    cursor: pointer; /* Add this */
}

/* Add this new style */
.overlay[href='#'] {
    cursor: default;
    background: #808080; /* Different color for 'Bio Coming Soon' */
}
  
  .cards__item__link:hover .overlay {
    opacity: 1;
    transition: all 0.2s linear;
    align-self: center;
}