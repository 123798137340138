.newfooter-container {
    background-color: #242424;
    padding: 1rem 0 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .website-rights {
    color: #fff;
    margin-bottom: 16px;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }

  .logo-container-footer {
    width: 25%;
    height: auto;
  }
  

  