@import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap');


  .footer-container {
    background-color: #242424;
    padding: 0 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  input::placeholder,
  textarea::placeholder {
    text-align: center; /* Center text horizontally */
  }

  .logo-container-footer {
    width: 30%;
    height: auto;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
    color: #fff;
  }

  .subscription-frame {
    height: 85vh;
    width: 65vw;
    /* box-shadow: 5px 5px 56px 0px rgba(0,0,0,0.25); */
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-top: 24px;
    margin-bottom: 24px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 2rem;
    letter-spacing: 8px;
    text-transform: uppercase;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
    align-self: center;
  }

  .input-areas {
    justify-items: right;
  }
  
  .form-container {
    align-items: center;
  }

  .fields-container {
    flex-flow: column;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Three equal columns */
    align-self: center;
  
  }

  .footer-input {
    padding: 10px 2px;
    margin: 3px 4px;
    border-radius: 6px;
    outline: none;
    border: none;
    font-size: 1rem;
    margin-bottom: 16px;
    border: 1px solid #fff;
    justify-content: center;
    display: grid;
    justify-content: center; 
    max-width: 90%;
    justify-self: center;
  }

  .footer-input-message {
    font-size: 1rem;

    padding: 5px 10px;
    margin: 3px 10px;
    margin-bottom: 15px;
    border-radius: 6px;
    width: 95%;
    justify-self: center;
    justify-content: center;
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: middle;
    margin: 16px;
    text-align: center;
    width: 160px;
    box-sizing: border-box;
  }
  
  .footer-link-items h2 {
    margin-bottom: 16px;
  }
  
  .footer-link-items > h2 {
    color: #fff;
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 16px;
  }
  

  .email-sent-message {
    text-align: center;
    padding: 20px;
    background-color: #4CAF50;
    color: white;
    border-radius: 5px;
  }

  .footer-subscription-about  {
    max-width: 550px;
    width: 60%;
    padding-top: 24px;
    text-align: left;
    font-size: 1rem;
  }

  .large-icon-container {
    padding: 20px;
  }

  .icon-container {
    display: flex;
    align-items: center;
    align-content: center;
    padding: 10px;
  }

  .icon {
    margin-right: 8px; /* Add space to the right of the icon */
  }

  .icon-text {
    font-size: 1.2rem; /* Adjust font size as needed */
    color: white;
  }
  
  

  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }

    .footer-subscription-about  {
      text-align: justify;
    }
  }
  
  @media screen and (max-width: 768px) {
    .footer-subscription-about  {
      text-align: justify;
    }

    .footer-subscription-heading {
      font-size: 1.6rem;
    }
  }
