.cards {
    padding: 4rem;
    background: #fff;
  }

  h1 {
    text-align: center;
    font-size: 2rem;
    letter-spacing: 8px;
    text-transform: uppercase;
  }


  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 0px;
  }
  
  .cards__items {
    margin-bottom: 30px;
    max-width: 92vw;
    align-self: center;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 15px;
    margin-bottom: 15px;
    width: 50vw;
    align-self: center;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 100%;
    padding-bottom: 25%;
    overflow: hidden;
    object-fit: fill;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 6px 8px;
    max-width: 100%;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #500000;
    /* box-sizing: border-box; */
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    max-width: 100%;
    width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: fill;
    transform: scale(1.1);
    /* transition: all 0.2s linear; */
  }
  
  
  .cards__item__info {
    padding: 20px 8px 8px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 18px;
    padding: 0;
  }

  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
    .cards__items {
      display: flex;
      align-self: center;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
      align-self: center;
    }
  }
  
  @media only screen and (max-width: 900px) {
    .cards__item {
      margin-bottom: 2rem;
    }

    .card__header {
      font-size: 1.6rem;
    }
  }

  @media only screen and (max-width: 1024px) and (min-height: 1366px) {
    .cards__items {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* Three equal columns */
      gap: 20px; /* Space between cards */
      justify-content: center; /* Center the grid within its container */
      justify-self: center;
    }
  
    .cards__item {
      width: 100%; /* Ensure cards take full width of their grid cell */
      justify-self: center;
    }
  
    /* Target the last two items to center them in the second row */
    .cards__item:nth-last-child(2),
    .cards__item:last-child {
      grid-column: span 1; /* Each item spans one column */
      justify-self: center; /* Center each item horizontally */
    }
  }
  